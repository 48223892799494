/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'gatsby';

import rollert from '../images/team/rollert.png';
import roux from '../images/team/steve-leroux.png';
import bond from '../images/team/bond.png';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import SignUp from '../components/website/SignUp';
import Phone from '../components/website/Phone';
import { Header, BodyBold, Body, ArticleWrapper, ArticleImg, Wrapper, ArticleBody } from '../styles';

const Press = () => (
	<Layout>
		<MetaData
			title="U.S. Banking Challenger with South African heritage, ENVEL Inc. closes R46 million seed round"
			description="Envel - U.S. Banking Challenger with South African heritage, ENVEL Inc. closes R46 million seed round"
			image="images/social/socialSeedSA.png"
		/>
		<Header center>
			U.S. Banking Challenger with South African heritage, ENVEL Inc. closes R46 million seed round
		</Header>
		<ArticleBody>
			<Body>
				<BodyBold>BOSTON, Sept. 17, 2020 / ACCESSWIRE/ </BodyBold>
				Envel, a Harvard-founded, MIT-backed FinTech, have engineered the first ever AI-based Autonomous Bank Account to
				manage users’ finances on Autopilot. Launching in fall of 2020, Envel raised R46M seed investment from U.S.
				Angels and Family Offices who are committed to improving budgeting and financial stability for millions of
				Americans, particularly relevant during the current crisis. Thousands have signed up to the waitlist eager to
				take back control of their finances.
			</Body>

			<Body>Steve Le Roux, Envel Founder and CEO, says</Body>
			<ArticleWrapper row justifyCenter alignCenter>
				<ArticleImg src={roux} />
				<Body>
					"It has been a personal mission of mine to build an autonomous digital bank that proactively helps the
					majority of people who don't have the time, skills or indeed desire to manage their finances. Let's face it,
					the socioeconomic challenge we face today is that managing finances and budgeting, is for most, far too
					mentally taxing around our busy lives, and makes us feel terrible knowing we could have been better at
					reaching our goals. So many of the dreams people have, have been impeded by a lack of financial discipline.
					Just imagine how amazing the world would be if at the very least a bank could do all the heavy lifting for you
					in real-time driven by the power of AI, helping manage every precious cent, neutralizing anxiety and making it
					fun. Autonomous banking is the future and we're incredibly grateful to Paul Pagnato and all our investors for
					helping support our mission!"
				</Body>
			</ArticleWrapper>

			<Body>
				Envel’s digital banking app is designed for a young generation of consumers in need of financial guidance. Envel
				uses its patent-pending AI technology and insights from the intersection of behavioral science and Artificial
				Intelligence to help Americans better manage their money. Envel allows users to have real control over their
				finances with the ability to turn their account on “Autopilot” to help build wealth autonomously. The app is
				designed to be fun, simple, and user-friendly, a stark contrast to traditional banking apps. Envel aims to
				gamify financial management to attract and engage a younger generation of consumers.
			</Body>

			<Body>Craig Bond, Envel Chairman, says</Body>
			<ArticleWrapper row justifyCenter alignCenter>
				<ArticleImg src={bond} />
				<Body>
					"Arising out of the global COVID-19 pandemic and resulting economic depression, Envel’s mission to improve the
					financial health of its users is now more critical than ever. A recent study by the Federal Reserve found that
					nearly half of all Americans couldn’t cover a $400 emergency expense without selling something or borrowing
					money. Envel aims to alleviate this problem with its unique patent-pending AI-powered "Envelope" system, which
					sorts a user's paycheck on Autopilot into four different accounts – guilt-free daily or weekly Spending,
					Bills, Emergency funds, and the "Vault” (a long-term nest egg) – to dramatically improve their financial
					health and keep them on the right path without considerable effort."
				</Body>
			</ArticleWrapper>

			<Body>
				Dr. John Paul Rollert is an Adjunct Assistant Professor of Behavioral Science at The University of Chicago Booth
				School of Business and teaches leadership, ethics, and politics at Harvard University, denotes that
			</Body>
			<ArticleWrapper row justifyCenter alignCenter>
				<ArticleImg src={rollert} />
				<Body>
					"Studies have consistently shown that developing good financial habits when you are young contributes to
					long-term personal stability in addition to physical and mental well-being, the mission of Envel is to support
					these goals by enhancing financial literacy among young people and providing them a banking experience that
					underwrites these aims"
				</Body>
			</ArticleWrapper>

			<Body>
				Envel initially plans to target college students, Gen Z and Millennials, with the long-term goal of guiding 1
				million students out of debt. With 45 million Americans owing more than $1.6 trillion in student loan debt and
				75% of American workers living paycheck to paycheck, Envel aims to play a critical role in helping young people
				become financially fit and develop sustainable spending habits so that they don’t struggle with debt for decades
				after they graduate college.
			</Body>

			{/* <Wrapper alignCenter>
					<Phone />
				</Wrapper> */}

			<Body>
				<BodyBold>About Envel, Inc.</BodyBold>
			</Body>
			<Body>
				Envel is a U.S. AI-based Autonomous Banking Challenger, providing customers with the ability to open up to 99
				FDIC insured[1] bank accounts within seconds powered by its envelope system. Envel provides users with a
				contactless Envel Visa® Debit Card[1] as well as access to Apple Pay and Google Pay. Envel enables a fully
				autonomous experience with budgeting and savings on Autopilot. The Envel app claims to be digital banking's
				fastest frictionless experience and has been designed to draw upon behavioral science insights to take the
				anxiety out of opening a bank account and managing one’s finances.
			</Body>
			<Body>
				Headquartered in Boston, Massachusetts, with its engineering and data science office based in Cape Town, South
				Africa, Envel has strong connections to Harvard and MIT, serving as the title sponsor of both HackHarvard 2019
				and the MIT Fintech conference 2020. It is a member of DCU FinTech Innovation Lab and a start-up member of MIT
				CSAIL (Computer Science and Artificial Intelligence Lab).
			</Body>
			<Body>
				The Envel team is led by founder and CEO Steve Le Roux, who came up with the idea for Envel in 2016 while
				studying at Harvard. Together with Co-founder Diederik Meeuwis, the Envel Team includes talented individuals who
				have worked at Paypal, Barclays, Santander, Wells Fargo, JP Morgan & Chase, Absa and Circle. Craig Bond serves
				as Envel’s Chairman and President. He is a former CEO of Barclays Africa and Absa Retail and Business Banking, a
				former CEO of Standard Bank Africa and American Express South Africa, and a former Chairman of MasterCard (South
				Asia Middle East Africa Board) and Ford Financial Services (Pty)Ltd.
			</Body>
			<Body>
				For more on Envel, see <Link to="/">www.envel.ai</Link>.
			</Body>
			<Body>
				<BodyBold>Investors</BodyBold>
			</Body>
			<Body>
				Pre-seed for Envel was funded by the MIT Sandbox Program. Seed-funding has been provided by a group of
				purpose-driven investors, led by Paul A. Pagnato, Founder of PagnatoKarp and Co-Chairman of Cresset Capital.
				Other investors include Mike Baxter, Senior Partner, Bain & Company. Fielding Miller, CEO of CapTrust, the
				largest private RIA in the U.S. with over $390 Billion AUM. David Canter, EVP and Head RIA, Fidelity
				Investments. Will Weisman, Executive Director, Singularity University and MD, KittyHawk Ventures. Eric Becker,
				Founder and Co-Chairman Cresset Capital. Neal Rothermel, Principal Officer, VMS Biomarketing. Jim Haudan,
				Chairman of Root Inc. Jim Schleckser, CEO & Founder of Inc.CEO Project. And Raj Sharma, MD and Head, Merrill
				Lynch Private Wealth Management.
			</Body>
			<Body>
				<BodyBold>MEDIA CONTACT</BodyBold>
				<br />
				Jacqueline Epstein
				<br />
				<a href="mailto:pr-contact@envel.ai" target="_blank" rel="noopener noreferrer">
					pr-contact@envel.ai
				</a>
			</Body>
			<Body>
				<BodyBold>SOURCE:</BodyBold> Envel, Inc.
			</Body>
			<Body className="subtext">
				[1] FDIC insurance is provided by nbkc bank, Member FDIC. The Envel Visa® Debit Card is issued by nbkc bank
				pursuant to a license from Visa U.S.A. Inc.
			</Body>
		</ArticleBody>
		{/* <SignUp /> */}
	</Layout>
);

export default Press;
